import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P76-E1',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page76/E1/1.jpg',
				},
			],
		],
	},
}

export default json
