// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterfaceV2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'LQTAMN2-U1-P9-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{
					url: 'img/FriendsPlus/Page9/E1/1.jpg',
					audioImgUrl: 'Audios/Track-2-details/Im-a-bird.mp3',
					status_audio: 0,
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'LQTAMN2-U1-P9-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{
					url: 'img/FriendsPlus/Page9/E2/1.jpg',
					audioImgUrl: 'Audios/Track-2-details/Im-a-chicken.mp3',
					status_audio: 0,
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'LQTAMN2-U1-P9-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{
					url: 'img/FriendsPlus/Page9/E3/1.jpg',
					audioImgUrl: 'Audios/Track-2-details/Track-2.mp3',
					status_audio: 1,
				},
			],
		],
	},
}

export default json
