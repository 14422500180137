import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'LQTAMN2-U3-P32-E1',
		audio: '',
		video: '',
		component: D1,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page32/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page32/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page32/E1/3.jpg',
					audioUrl: 'Audios/Track-7-details/Its-sunny.mp3',
				},
				{
					url: 'img/FriendsPlus/Page32/E1/4.jpg',
					audioUrl: 'Audios/Track-7-details/Its-cloudy.mp3',
				},
				{
					url: 'img/FriendsPlus/Page32/E1/5.jpg',
					audioUrl: 'Audios/Track-7-details/Its-windy.mp3',
				},
				{
					url: 'img/FriendsPlus/Page32/E1/6.jpg',
					audioUrl: 'Audios/Track-7-details/Its-rainy.mp3',
				},
				{
					url: 'img/FriendsPlus/Page32/E1/7.jpg',
					audioUrl: 'Audios/Track-7-details/Its-snowy.mp3',
				},
				{ url: 'img/FriendsPlus/Page32/E1/8.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page32/E1/9.jpg' },
			],
		],
	},
}

export default json
