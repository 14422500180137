import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit Starter',
		id: 'LQTAMN2-US-P4-E1',
		audio: '',
		video: '',
		exerciseKey: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isHiddenCheck: true,
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page4/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page4/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/3.jpg',
					audioUrl: 'Audios/Track-1-details/Track-1.mp3',
				},
				{
					url: 'img/FriendsPlus/Page4/E1/4.jpg',
					audioUrl: 'Audios/Track-1-details/Track-1.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/5.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page4/E1/6.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page4/E1/7.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/8.jpg',
					audioUrl: 'Audios/Track-1-details/Whats-your-name.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/9.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page4/E1/10.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/11.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/12.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/13.jpg',
					audioUrl: 'Audios/Track-1-details/Whats-your-name.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/14.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page4/E1/15.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/16.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/17.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/18.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/19.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/20.jpg',
					audioUrl: 'Audios/Track-1-details/Whats-your-name.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/21.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page4/E1/22.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/23.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/24.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/25.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/26.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page4/E1/27.jpg' },
				{
					url: 'img/FriendsPlus/Page4/E1/28.jpg',
					audioUrl: 'Audios/Track-1-details/My-names-Duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page4/E1/29.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page4/E1/30.jpg' }],
		],
	},
}

export default json
