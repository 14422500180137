import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P18-E1',
		audio: '',
		video: 'Videos/Unit 2_Track 5_One little duck!.mp4',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page18/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page18/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page18/E1/3.jpg',
					audioUrl: 'Audios/Track-5-details/Track-5.mp3',
				},
				{
					url: 'img/FriendsPlus/Page18/E1/4.jpg',
					audioUrl: 'Audios/Track-5-details/Track-5.mp3',
				},
				{
					url: 'img/FriendsPlus/Page18/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page18/E1/6.jpg',
				},
			],
		],
	},
}

export default json
