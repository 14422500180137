import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P21-E1',
		audio: '',
		video: '',
		component: D1,
		//recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page21/E1/a.jpg' },
			],
		],
	},
}

export default json
