import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P26-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page26/E1/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '',
				title: '',
				color: '',
			},
		],

		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '206px',
							left: '220px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '580px',
							left: '342px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '326px',
							left: '221px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '324px',
							left: '340px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '454px',
							left: '221px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '205px',
							left: '340px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '582px',
							left: '220px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '699px',
							left: '340px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '702px',
							left: '220px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
					//10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '451px',
							left: '340px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							// backgroundColor: 'purple',
						},
					},
				],
				answers: ['0-1', '2-3', '4-5', '6-7', '8-9'],
				initialValue: ['0-1'],
			},
			Layout: `
          <img src='img/FriendsPlus/Page26/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
		},
	},
}

export default json
