import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P22-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column
				{
					url: 'img/FriendsPlus/Page22/E1/1.jpg',
					audioUrl: 'Audios/Track-5-details/One-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page22/E1/2.jpg',
					audioUrl: 'Audios/Track-5-details/Two-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page22/E1/3.jpg',
					audioUrl: 'Audios/Track-5-details/Three-2.mp3',
				},
			],
			[
				// Column
				{
					url: 'img/FriendsPlus/Page22/E1/4.jpg',
					audioUrl: 'Audios/Track-5-details/Four-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page22/E1/5.jpg',
				},
				{
					url: 'img/FriendsPlus/Page22/E1/6.jpg',
					audioUrl: 'Audios/Track-5-details/Five-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page22/E1/7.jpg',
					audioUrl: 'Audios/Track-5-details/Three-2.mp3',
				},
			],
			[
				// Column
				{
					url: 'img/FriendsPlus/Page22/E1/8.jpg',
					audioUrl: 'Audios/Track-5-details/Four-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page22/E1/9.jpg' },
				{
					url: 'img/FriendsPlus/Page22/E1/10.jpg',
					audioUrl: 'Audios/Track-5-details/Five-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page22/E1/11.jpg' },
			],
		],
	},
}

export default json
