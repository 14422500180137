import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		// Exercise num
		unit: 'Unit Starter',
		id: 'LQTAMN2-US-P5-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page5/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page5/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page5/E1/3.jpg',
				},
				{ url: 'img/FriendsPlus/Page5/E1/4.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page5/E1/5.jpg' },
				{
					url: 'img/FriendsPlus/Page5/E1/6.jpg',
					audioUrl: 'Audios/Track-1-details/Im-sorry.mp3',
				},
				{
					url: 'img/FriendsPlus/Page5/E1/7.jpg',
					audioUrl: 'Audios/Track-1-details/Im-sorry.mp3',
				},
				{
					url: 'img/FriendsPlus/Page5/E1/8.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E1/9.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E1/10.jpg',
				},
			],
			[{ url: 'img/FriendsPlus/Page5/E1/11.jpg' }],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit Starter',
		id: 'LQTAMN2-US-P5-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page5/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page5/E2/2.jpg' },
				{
					url: 'img/FriendsPlus/Page5/E2/3.jpg',
				},
				{ url: 'img/FriendsPlus/Page5/E2/4.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page5/E2/5.jpg' },
				{
					url: 'img/FriendsPlus/Page5/E2/6.jpg',
					audioUrl: 'Audios/Track-1-details/Thank-you.mp3',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/7.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/8.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page5/E2/9.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/10.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/11.jpg',
					audioUrl: 'Audios/Track-1-details/Thank-you.mp3',
				},
				{ url: 'img/FriendsPlus/Page5/E2/12.jpg' },
				{ url: 'img/FriendsPlus/Page5/E2/13.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page5/E2/14.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/15.jpg',
				},
				{
					url: 'img/FriendsPlus/Page5/E2/16.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page5/E2/17.jpg',
				},
			],
		],
	},
}

export default json
