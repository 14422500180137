import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'LQTAMN2-U5-P47-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page47/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/3.jpg',
					audioUrl: 'Audios/Track-12-details/Track-12.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/4.jpg',
					audioUrl: 'Audios/Track-12-details/Track-12.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/6.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/7.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-milk.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/8.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/9.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/10.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-tea.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/11.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/12.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-milk.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/13.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/14.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/15.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-tea.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/16.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/17.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/18.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-tea.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/19.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/20.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-juice.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/21.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/22.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/23.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-juice.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/24.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/25.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/26.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-water-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/27.jpg',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/28.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-juice.mp3',
				},
				{
					url: 'img/FriendsPlus/Page47/E1/29.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page47/E1/30.jpg',
				},
			],
		],
	},
}

export default json
