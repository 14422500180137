import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'LQTAMN2-U3-P28-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page28/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page28/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page28/E1/3.jpg',
					audioUrl: 'Audios/Track-7-details/Track-7.mp3',
				},
				{
					url: 'img/FriendsPlus/Page28/E1/4.jpg',
					audioUrl: 'Audios/Track-7-details/Track-7.mp3',
				},
				{ url: 'img/FriendsPlus/Page28/E1/5.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page28/E1/6.jpg' }],
			[
				{
					url: 'img/FriendsPlus/Page28/E1/7.jpg',
					audioUrl: 'Audios/Track-7-details/Hows-the-weather-Its-sunny.mp3',
				},
				{
					url: 'img/FriendsPlus/Page28/E1/8.jpg',
					audioUrl: 'Audios/Track-7-details/Hows-the-weather-Its-cloudy.mp3',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		id: 'LQTAMN2-U3-P28-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{
					url: 'img/FriendsPlus/Page28/E2/1.jpg',
					audioUrl: 'Audios/Track-7-details/Hows-the-weather-Its-sunny.mp3',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 3',
		id: 'LQTAMN2-U3-P28-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page28/E3/1.jpg',
					audioUrl: 'Audios/Track-7-details/Hows-the-weather-Its-cloudy.mp3',
				},
			],
		],
	},
}

export default json
