import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P63-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		recorder: true,
		totalInput: 1,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page63/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E1/2.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E1/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page63/E1/4.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E1/6.jpg',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P63-E2',
		audio: '',
		video: '',
		component: DesignUnderLine,
		recorder: true,
		totalInput: 1,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page63/E2/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E2/2.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E2/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page63/E2/4.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E2/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E2/6.jpg',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P63-E3',
		audio: '',
		video: '',
		component: DesignUnderLine,
		recorder: true,
		totalInput: 1,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page63/E3/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E3/2.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E3/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page63/E3/4.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E3/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E3/6.jpg',
				},
			],
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P63-E4',
		audio: '',
		video: '',
		component: DesignUnderLine,
		recorder: true,
		totalInput: 1,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page63/E4/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E4/2.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E4/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page63/E4/4.jpg',
					input: 1,
				},
				{
					url: 'img/FriendsPlus/Page63/E4/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page63/E4/6.jpg',
				},
			],
		],
	},
}

export default json
