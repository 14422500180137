// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import UI from '../../components/ExcerciseTypes/Design/UserInterfaceV2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'LQTAMN2-U1-P10-E1',
		audio: '',
		video: "Videos/Unit 1_Track 3_I'm a little cat!.mp4",
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page10/E1/1.jpg' }],
			[
				{
					url: 'img/FriendsPlus/Page10/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page10/E1/3.jpg',
					audioImgUrl: 'Audios/Track-3-details/Track-3.mp3 ',
					status_audio: 1,
				},
				{
					url: 'img/FriendsPlus/Page10/E1/4.jpg',
				},
				{
					url: 'img/FriendsPlus/Page10/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page10/E1/6.jpg',
				},
			],
		],
	},
}

export default json
