import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P36-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{ url: 'img/FriendsPlus/Page36/E1/1.jpg' },
				{
					url: 'img/FriendsPlus/Page36/E1/2.jpg',
					audioUrl: 'Audios/Track-9-details/Track-9.mp3',
				},
				{
					url: 'img/FriendsPlus/Page36/E1/3.jpg',
					audioUrl: 'Audios/Track-9-details/Track-9.mp3',
				},
				{ url: 'img/FriendsPlus/Page36/E1/4.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page36/E1/5.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P36-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page36/E2/1.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
			],
		],
	},
}

export default json
