import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P66-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page66/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/3.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/4.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/5.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/6.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-flowers.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/7.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/8.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/9.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/10.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/11.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/12.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-flowers.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/13.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/14.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/15.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/16.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-vegetables.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/17.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/18.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/19.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/20.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/21.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-flowers.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/22.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/23.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/24.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/25.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-vegetables.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/26.jpg',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/27.jpg',
					audioUrl: 'Audios/Track-15-details/What-can-you-see.mp3',
				},
				{
					url: 'img/FriendsPlus/Page66/E1/28.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page66/E1/29.jpg',
				},
			],
		],
	},
}

export default json
