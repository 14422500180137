import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import TypeInNoSubmit from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'LQTAMN2-U3-P33-E1',
		audio: '',
		video: '',
		component: TypeInNoSubmit,
		//recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page33/E1/a.jpg' },
			],
		],
	},
}

export default json
