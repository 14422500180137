import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P73-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page73/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page73/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page73/E1/3.jpg',
					audioUrl: 'Audios/Track-17-details/Track-17.mp3',
				},
				{
					url: 'img/FriendsPlus/Page73/E1/4.jpg',
					audioUrl: 'Audios/Track-17-details/Track-17.mp3',
				},
				{
					url: 'img/FriendsPlus/Page73/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page73/E1/6.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page73/E1/7.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-six-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page73/E1/8.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-seven-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page73/E1/9.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-eight-balloons.mp3',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page73/E1/10.jpg',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P73-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page73/E2/1.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-six-balloons.mp3',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P73-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page73/E3/1.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-seven-balloons.mp3',
				},
			],
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P73-E4',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page73/E4/1.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-eight-balloons.mp3',
				},
			],
		],
	},
}

export default json
