
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TypeInNoSubmit from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
		id: 'LQTAMN2-U1-P13-E1',
    audio: '',
    video: '',
    component: TypeInNoSubmit,
    //recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E1/a.jpg' },
        
      ],
      

    ],
  },
 
}

export default json;