import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'LQTAMN2-U5-P49-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 1,
		exerciseKey: '',
		titleImage: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page49/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page49/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page49/E1/3.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-water-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/4.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page49/E1/5.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-milk.mp3',
				},
				{
					url: 'img/FriendsPlus/Page49/E1/6.jpg',
				},
				{
					url: 'img/FriendsPlus/Page49/E1/7.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-water-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/8.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page49/E1/9.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-milk.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/10.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page49/E1/11.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page49/E1/12.jpg' },
				{
					url: 'img/FriendsPlus/Page49/E1/13.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-tea.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/14.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page49/E1/15.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-juice.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/16.jpg' },
				{
					url: 'img/FriendsPlus/Page49/E1/17.jpg',
					audioUrl: 'Audios/Track-12-details/I-like-tea.mp3',
				},
				{ url: 'img/FriendsPlus/Page49/E1/18.jpg' },
			],
		],
		questions: [
			{
				title: '',
				answer: [],
			},
		],
	},
	// 1: {
	// 	// Exercise num
	// 	unit: 'Unit 5',
	// 	id: 'LQTAMN2-U5-P49-E1',
	// 	audio: 'img/FriendsPlus/Page49/Audio/audio.e1.mp3',
	// 	video: '',
	// 	component: D1,
	// 	recorder: true,
	// 	isAllowSubmit: false,
	// 	questionImage: [
	// 		// Row
	// 		[
	// 			// Column1
	// 			{ url: 'img/FriendsPlus/Page49/E1/1.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page49/E1/2.jpg',
	// 				audioUrl: 'img/FriendsPlus/Page49/Audio/tieude.e1.mp3',
	// 			},
	// 			{ url: 'img/FriendsPlus/Page49/E1/3.jpg' },
	// 		],
	// 		[
	// 			// Column2
	// 			{
	// 				url: 'img/FriendsPlus/Page49/E1/4.jpg',
	// 				audioUrl: 'img/FriendsPlus/Page49/Audio/circle.mp3',
	// 			},
	// 			{
	// 				url: 'img/FriendsPlus/Page49/E1/5.jpg',
	// 				audioUrl: 'img/FriendsPlus/Page49/Audio/square.mp3',
	// 			},
	// 			{
	// 				url: 'img/FriendsPlus/Page49/E1/6.jpg',
	// 				audioUrl: 'img/FriendsPlus/Page49/Audio/triangle.mp3',
	// 			},
	// 		],
	// 	],
	// },
	// 2: {
	// 	// Exercise num
	// 	unit: 'Unit 5',
	// 	id: 'LQTAMN2-U5-P49-E2',
	// 	audio: '',
	// 	video: '',
	// 	component: D1,
	// 	recorder: true,
	// 	isAllowSubmit: false,
	// 	question: [],
	// 	questionImage: [[{ url: 'img/FriendsPlus/Page49/E2/1.jpg' }]],
	// },
	// 3: {
	// 	// Exercise num
	// 	unit: 'Unit 5',
	// 	id: 'LQTAMN2-U5-P49-E3',
	// 	audio: '',
	// 	video: '',
	// 	component: D1,
	// 	recorder: true,
	// 	isAllowSubmit: false,
	// 	question: [],
	// 	questionImage: [[{ url: 'img/FriendsPlus/Page49/E3/1.jpg' }]],
	// },
}
export default json
