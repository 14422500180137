import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P75-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page75/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page75/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page75/E1/3.jpg',
					audioUrl: 'Audios/Track-18-details/Track-18.mp3',
				},
				{
					url: 'img/FriendsPlus/Page75/E1/4.jpg',
					audioUrl: 'Audios/Track-18-details/Track-18.mp3',
				},
				{
					url: 'img/FriendsPlus/Page75/E1/5.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page75/E1/6.jpg',
				},
			],
		],
	},
}

export default json
