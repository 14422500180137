import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P57-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page57/E1/1.jpg',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/2.jpg',
					audioUrl: 'Audios/Track-13-details/Im-happy.mp3',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/3.jpg',
					audioUrl: 'Audios/Track-13-details/Im-hungry.mp3',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page57/E1/4.jpg',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/5.jpg',
					audioUrl: 'Audios/Track-13-details/Im-great.mp3',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/6.jpg',
					audioUrl: 'Audios/Track-13-details/Im-thirsty.mp3',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page57/E1/7.jpg',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/8.jpg',
					audioUrl: 'Audios/Track-13-details/How-do-you-feel.mp3',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/9.jpg',
					audioUrl: 'Audios/Track-13-details/Im-great.mp3',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/10.jpg',
					audioUrl: 'Audios/Track-13-details/Im-thirsty.mp3',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page57/E1/11.jpg',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/12.jpg',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/13.jpg',
					audioUrl: 'Audios/Track-13-details/How-do-you-feel.mp3',
				},
				{
					url: 'img/FriendsPlus/Page57/E1/14.jpg',
				},
			],
		],
	},
}

export default json
