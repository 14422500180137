import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P77-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page77/E1/1.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E1/2.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page77/E1/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E1/4.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-six-balloons.mp3',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P77-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page77/E2/1.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E2/2.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page77/E2/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E2/4.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-seven-balloons.mp3',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P77-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page77/E3/1.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E3/2.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page77/E3/3.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-eight-balloons.mp3',
				},
			],
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P77-E4',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page77/E4/1.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page77/E4/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page77/E4/3.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-nine-balloons.mp3',
				},
			],
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P77-E5',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page77/E5/1.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page77/E5/2.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-ten-balloons.mp3',
				},
			],
		],
	},
}

export default json
