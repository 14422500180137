import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		// Exercise num
		unit: 'Unit Starter',
		id: 'LQTAMN2-US-P7-E1',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[
				// Column1
				{ url: 'img/FriendsPlus/Page7/E1/1.jpg' },
			],
			[
				// Column1
				{ url: 'img/FriendsPlus/Page7/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page7/E1/3.jpg',
					audioUrl: 'Audios/Track-1-details/Be-quiet-please.mp3',
				},
				{ url: 'img/FriendsPlus/Page7/E1/4.jpg' },
			],
			[
				// Column1
				{ url: 'img/FriendsPlus/Page7/E1/5.jpg' },
			],
		],
	},
}

export default json
