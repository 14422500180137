import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P25-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '',
				title: '',
				color: '',
			},
		],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '162px',
							left: '86px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '162px',
							left: '331px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '162px',
							left: '584px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '162px',
							left: '829px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '454px',
							left: '86px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '454px',
							left: '331px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '454px',
							left: '557px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
					//8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '454px',
							left: '829px',
							width: 20,
							height: 20,
							borderRadius: '50%',
							backgroundColor: 'purple',
						},
					},
				],
				answers: ['2-4', '3-5', '0-6', '1-7'],
				initialValue: ['0-6'],
			},
			Layout: `
          <img src='img/FriendsPlus/Page25/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
		},
	},
}

export default json
