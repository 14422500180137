import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P3-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "img/FriendsPlus/Page3/E1/b.jpg",
      //     audioUrl: "",
      //   },
      //   { url: "img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      // [{ url: "img/FriendsPlus/Page3/1.jpg" }],
    ],
  },
};

export default json;
