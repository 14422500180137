import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P74-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page74/E1/1.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-nine-balloons.mp3',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P74-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page74/E2/1.jpg',
					audioUrl:
						'Audios/Track-17-details/How-many-balloons-are-there-There-are-ten-balloons.mp3',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P74-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page74/E3/3.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/4.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-six-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/5.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-seven-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/6.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-eight-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/7.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-nine-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/8.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-ten-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/9.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page74/E3/10.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/11.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-six-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/12.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/13.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-seven-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/14.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/15.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-eight-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/16.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/17.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-nine-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/18.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/19.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-ten-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/20.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/21.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/22.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page74/E3/23.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/24.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-six-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/25.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-seven-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/26.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-eight-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/27.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-nine-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/28.jpg',
					audioUrl: 'Audios/Track-17-details/There-are-ten-balloons.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/29.jpg',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/30.jpg',
					audioUrl: 'Audios/Track-17-details/How-many-balloons-are-there.mp3',
				},
				{
					url: 'img/FriendsPlus/Page74/E3/31.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page74/E3/32.jpg',
				},
			],
		],
	},
}

export default json
