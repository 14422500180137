import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: 'LQTAMN2-U6-P67-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page67/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/3.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/4.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/5.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/6.jpg',
					audioUrl: 'Audios/Track-16-details/Track-16.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/7.jpg',
					audioUrl: 'Audios/Track-16-details/Track-16.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/8.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/9.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/10.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/11.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/12.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/13.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/14.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/15.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/16.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/17.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/18.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/19.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/20.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/21.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/22.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/23.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/24.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/25.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/26.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/27.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/28.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/29.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/30.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/31.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/32.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/33.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/34.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/35.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/36.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/37.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/38.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/39.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/40.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/41.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/42.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/43.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/44.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/45.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/46.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/47.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/48.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/49.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-grasses-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/50.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/51.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-trees-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/52.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-grasses-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/53.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-vegetables-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/54.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/55.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/56.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/57.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-flowers-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/58.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/59.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-grasses-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/60.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/61.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/62.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-grasses-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/63.jpg',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/64.jpg',
					audioUrl: 'Audios/Track-16-details/I-can-see-the-grasses-3.mp3',
				},
				{
					url: 'img/FriendsPlus/Page67/E1/65.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page67/E1/66.jpg',
				},
			],
		],
	},
}

export default json
