import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'LQTAMN2-U2-P24-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column
				{
					url: 'img/FriendsPlus/Page24/E1/1.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/2.jpg',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/3.jpg',
					audioUrl: 'Audios/Track-6-details/Track-6.mp3',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/4.jpg',
					audioUrl: 'Audios/Track-6-details/Track-6.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/5.jpg' },
				{
					url: 'img/FriendsPlus/Page24/E1/6.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-bird-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/7.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/8.jpg',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/9.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-bird-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/10.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/11.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/12.jpg',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/13.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-dog-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/14.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/15.jpg',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/16.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-dog-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/17.jpg' },
				{
					url: 'img/FriendsPlus/Page24/E1/18.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/19.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page24/E1/20.jpg' },
				{
					url: 'img/FriendsPlus/Page24/E1/21.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-chicken-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/22.jpg' },
				{
					url: 'img/FriendsPlus/Page24/E1/23.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-duck.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/24.jpg' },
				{ url: 'img/FriendsPlus/Page24/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page24/E1/26.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/27.jpg',
				},
				{
					url: 'img/FriendsPlus/Page24/E1/28.jpg',
					audioUrl: 'Audios/Track-6-details/Im-a-chicken-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page24/E1/29.jpg' },
			],
			[
				{
					url: 'img/FriendsPlus/Page24/E1/30.jpg',
				},
			],
		],
	},
	// 1: {
	// 	// Exercise num
	// 	unit: 'Unit 2',
	// 	id: 'LQTAMN2-U2-P124-E1',
	// 	audio: '',
	// 	video: '',
	// 	component: D1,
	// 	recorder: true,
	// 	isAllowSubmit: false,
	// 	questionImage: [
	// 		// Row
	// 		[
	// 			// Column1
	// 			{ url: 'img/FriendsPlus/Page24/E1/1.jpg' },
	// 		],
	// 		[
	// 			// Column1
	// 			{ url: 'img/FriendsPlus/Page24/E1/2.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/3.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Track-6.mp3',
	// 			}, //track 6
	// 			{ url: 'img/FriendsPlus/Page24/E1/4.jpg' },
	// 			{ url: 'img/FriendsPlus/Page24/E1/5.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/6.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-bird-2.mp3',
	// 			}, //track bird
	// 			{ url: 'img/FriendsPlus/Page24/E1/7.jpg' },
	// 		],
	// 		[
	// 			// Column2
	// 			{ url: 'img/FriendsPlus/Page24/E1/8.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/9.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-bird-2.mp3',
	// 			}, //track bird
	// 			{ url: 'img/FriendsPlus/Page24/E1/10.jpg' },
	// 		],
	// 		[
	// 			// Column3
	// 			{ url: 'img/FriendsPlus/Page24/E1/11.jpg' },
	// 		],
	// 		[
	// 			// Column4
	// 			{ url: 'img/FriendsPlus/Page24/E1/12.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/13.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-dog-2.mp3',
	// 			}, // track dog
	// 			{ url: 'img/FriendsPlus/Page24/E1/14.jpg' },
	// 		],
	// 		[
	// 			// Column4
	// 			{ url: 'img/FriendsPlus/Page24/E1/15.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/16.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-chicken-2.mp3',
	// 			}, // track chicken
	// 			{ url: 'img/FriendsPlus/Page24/E1/17.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/18.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-duck.mp3',
	// 			}, // track duck
	// 			{ url: 'img/FriendsPlus/Page24/E1/19.jpg' },
	// 			{ url: 'img/FriendsPlus/Page24/E1/20.jpg' },
	// 			{ url: 'img/FriendsPlus/Page24/E1/21.jpg' },
	// 		],
	// 		[
	// 			// Column5
	// 			{ url: 'img/FriendsPlus/Page24/E1/22.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/23.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-chicken-2.mp3',
	// 			}, // track chicken
	// 			{ url: 'img/FriendsPlus/Page24/E1/24.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/25.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-cat-2.mp3',
	// 			}, // track cat
	// 			{ url: 'img/FriendsPlus/Page24/E1/26.jpg' },
	// 		],
	// 		[
	// 			// Column6
	// 			{ url: 'img/FriendsPlus/Page24/E1/27.jpg' },
	// 			{
	// 				url: 'img/FriendsPlus/Page24/E1/28.jpg',
	// 				audioUrl: 'Audios/Track-6-details/Im-a-cat-2.mp3',
	// 			}, // track cat
	// 			{ url: 'img/FriendsPlus/Page24/E1/29.jpg' },
	// 		],
	// 		[
	// 			// Column6
	// 			{ url: 'img/FriendsPlus/Page24/E1/30.jpg' },
	// 		],
	// 	],
	// },
}

export default json
