import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P41-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			[
				{
					url: 'img/FriendsPlus/Page41/E1/1.jpg',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/2.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-evening.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/3.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page41/E1/4.jpg',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/5.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-evening.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/6.jpg',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page41/E1/7.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-evening.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/8.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-afternoon.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/9.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/10.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
			],
			[
				{
					url: 'img/FriendsPlus/Page41/E1/11.jpg',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/12.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
				{
					url: 'img/FriendsPlus/Page41/E1/13.jpg',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P41-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			[
				{
					url: 'img/FriendsPlus/Page41/E2/1.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-morning.mp3',
				},
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P41-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			[
				{
					url: 'img/FriendsPlus/Page41/E3/1.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-afternoon.mp3',
				},
			],
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'LQTAMN2-U4-P41-E4',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		isAllowSubmit: false,
		questionImage: [
			[
				{
					url: 'img/FriendsPlus/Page41/E4/1.jpg',
					audioUrl: 'Audios/Track-9-details/In-the-evening.mp3',
				},
			],
		],
	},
}

export default json
