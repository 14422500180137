import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: 'LQTAMN2-U7-P72-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		recorder: true,
		totalInput: 1,
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{
					url: 'img/FriendsPlus/Page72/E1/1.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-flowers-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page72/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page72/E1/3.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-grass-2.mp3',
				},
				{ url: 'img/FriendsPlus/Page72/E1/4.jpg' },
				{
					url: 'img/FriendsPlus/Page72/E1/5.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-tree-2.mp3',
				},
				{
					url: 'img/FriendsPlus/Page72/E1/6.jpg',
				},
				{
					url: 'img/FriendsPlus/Page72/E1/7.jpg',
					audioUrl: 'Audios/Track-15-details/I-can-see-the-vegetables-2.mp3',
				},
			],
			[{ url: 'img/FriendsPlus/Page72/E1/8.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page72/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page72/E1/10.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page72/E1/11.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page72/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page72/E1/13.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page72/E1/14.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page72/E1/15.jpg' },
				{ url: 'img/FriendsPlus/Page72/E1/16.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page72/E1/17.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page72/E1/18.jpg' }],
		],
	},
}

export default json
