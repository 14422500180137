export const dataBook = {
	1: [[{ imgUrl: 'img/FriendsPlus/Page1/1.jpg', excerciseNum: 0 }]],
	2: [[{ imgUrl: 'img/FriendsPlus/Page2/1.jpg', excerciseNum: 0 }]],
	3: [[{ imgUrl: 'img/FriendsPlus/Page3/1.jpg', excerciseNum: 0 }]],
	4: [
		[{ imgUrl: 'img/FriendsPlus/Page4/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page4/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page4/3.jpg', excerciseNum: 0 }],
	],
	5: [
		[{ imgUrl: 'img/FriendsPlus/Page5/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page5/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page5/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page5/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page5/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page5/6.jpg', excerciseNum: 0 }],
	],
	6: [
		[{ imgUrl: 'img/FriendsPlus/Page6/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page6/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page6/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page6/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page6/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page6/6.jpg', excerciseNum: 0 }],
	],

	7: [
		[{ imgUrl: 'img/FriendsPlus/Page7/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page7/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page7/3.jpg', excerciseNum: 0 }],
	],
	8: [
		[{ imgUrl: 'img/FriendsPlus/Page8/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page8/2.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/3.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page8/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/5.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page8/6.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page8/7.jpg', excerciseNum: 0 }],
	],
	9: [
		[{ imgUrl: 'img/FriendsPlus/Page9/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page9/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page9/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page9/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page9/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page9/8.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page9/9.jpg', excerciseNum: 0 }],
	],
	10: [
		[{ imgUrl: 'img/FriendsPlus/Page10/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page10/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page10/3.jpg', excerciseNum: 0 }],
	],
	11: [
		[{ imgUrl: 'img/FriendsPlus/Page11/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page11/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page11/3.jpg', excerciseNum: 0 }],
	],
	12: [
		[
			{ imgUrl: 'img/FriendsPlus/Page12/1.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/2.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/3.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page12/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/7.jpg', excerciseNum: 0 },
		],
	],
	13: [
		[{ imgUrl: 'img/FriendsPlus/Page13/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page13/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page13/3.jpg', excerciseNum: 0 }],
	],
	14: [
		[{ imgUrl: 'img/FriendsPlus/Page14/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page14/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page14/3.jpg', excerciseNum: 0 }],
	],
	15: [
		[{ imgUrl: 'img/FriendsPlus/Page15/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page15/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page15/3.jpg', excerciseNum: 0 }],
	],
	16: [
		[{ imgUrl: 'img/FriendsPlus/Page16/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/4.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page16/9.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page16/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page16/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page16/12.jpg', excerciseNum: 0 }],
	],
	17: [
		[{ imgUrl: 'img/FriendsPlus/Page17/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page17/5.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page17/6.jpg', excerciseNum: 0 }],
	],

	18: [
		[{ imgUrl: 'img/FriendsPlus/Page18/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page18/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page18/3.jpg', excerciseNum: 0 }],
	],
	19: [
		[{ imgUrl: 'img/FriendsPlus/Page19/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page19/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page19/3.jpg', excerciseNum: 0 }],
	],
	20: [
		[{ imgUrl: 'img/FriendsPlus/Page20/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page20/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page20/3.jpg', excerciseNum: 0 }],
	],
	21: [
		[{ imgUrl: 'img/FriendsPlus/Page21/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page21/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page21/3.jpg', excerciseNum: 0 }],
	],
	22: [
		[{ imgUrl: 'img/FriendsPlus/Page22/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page22/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page22/3.jpg', excerciseNum: 0 }],
	],
	23: [
		[{ imgUrl: 'img/FriendsPlus/Page23/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page23/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page23/3.jpg', excerciseNum: 0 }],
	],
	24: [
		[{ imgUrl: 'img/FriendsPlus/Page24/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page24/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page24/3.jpg', excerciseNum: 0 }],
	],
	25: [
		[{ imgUrl: 'img/FriendsPlus/Page25/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page25/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page25/3.jpg', excerciseNum: 0 }],
	],
	26: [
		[{ imgUrl: 'img/FriendsPlus/Page26/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page26/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page26/3.jpg', excerciseNum: 0 }],
	],
	27: [
		[{ imgUrl: 'img/FriendsPlus/Page27/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page27/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page27/3.jpg', excerciseNum: 0 }],
	],
	28: [
		[{ imgUrl: 'img/FriendsPlus/Page28/1.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page28/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page28/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page28/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page28/6.jpg', excerciseNum: 0 }],
	],
	29: [
		[{ imgUrl: 'img/FriendsPlus/Page29/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page29/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page29/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page29/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page29/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page29/8.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page29/9.jpg', excerciseNum: 0 }],
	],
	30: [
		[{ imgUrl: 'img/FriendsPlus/Page30/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page30/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page30/3.jpg', excerciseNum: 0 }],
	],
	31: [
		[{ imgUrl: 'img/FriendsPlus/Page31/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page31/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page31/3.jpg', excerciseNum: 0 }],
	],
	32: [
		[{ imgUrl: 'img/FriendsPlus/Page32/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page32/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page32/3.jpg', excerciseNum: 0 }],
	],
	33: [
		[{ imgUrl: 'img/FriendsPlus/Page33/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page33/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page33/3.jpg', excerciseNum: 0 }],
	],
	34: [
		[{ imgUrl: 'img/FriendsPlus/Page34/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page34/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page34/3.jpg', excerciseNum: 2 }],
		[{ imgUrl: 'img/FriendsPlus/Page34/4.jpg', excerciseNum: 0 }],
	],
	35: [
		[{ imgUrl: 'img/FriendsPlus/Page35/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page35/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page35/3.jpg', excerciseNum: 0 }],
	],
	36: [
		[{ imgUrl: 'img/FriendsPlus/Page36/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page36/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page36/3.jpg', excerciseNum: 2 }],
		[{ imgUrl: 'img/FriendsPlus/Page36/4.jpg', excerciseNum: 0 }],
	],
	37: [
		[{ imgUrl: 'img/FriendsPlus/Page37/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page37/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page37/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page37/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page37/6.jpg', excerciseNum: 3 }],
		[{ imgUrl: 'img/FriendsPlus/Page37/7.jpg', excerciseNum: 0 }],
	],
	38: [
		[{ imgUrl: 'img/FriendsPlus/Page38/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page38/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page38/4.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page38/5.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page38/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page38/8.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/9.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page38/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page38/11.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page38/12.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page38/13.jpg', excerciseNum: 0 }],
	],
	39: [
		[{ imgUrl: 'img/FriendsPlus/Page39/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page39/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page39/3.jpg', excerciseNum: 0 }],
	],
	40: [
		[{ imgUrl: 'img/FriendsPlus/Page40/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page40/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page40/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page40/5.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page40/6.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/7.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page40/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page40/9.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page40/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page40/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page40/12.jpg', excerciseNum: 0 }],
	],
	41: [
		[{ imgUrl: 'img/FriendsPlus/Page41/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page41/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page41/4.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page41/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page41/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page41/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page41/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page41/10.jpg', excerciseNum: 0 }],
	],
	42: [
		[{ imgUrl: 'img/FriendsPlus/Page42/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page42/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page42/3.jpg', excerciseNum: 0 }],
	],
	43: [
		[{ imgUrl: 'img/FriendsPlus/Page43/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page43/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page43/3.jpg', excerciseNum: 0 }],
	],
	44: [
		[{ imgUrl: 'img/FriendsPlus/Page44/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page44/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page44/3.jpg', excerciseNum: 0 }],
	],
	45: [
		[{ imgUrl: 'img/FriendsPlus/Page45/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page45/2.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page45/4.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page45/5.jpg', excerciseNum: 0 }],
	],
	46: [
		[{ imgUrl: 'img/FriendsPlus/Page46/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page46/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page46/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page46/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page46/6.jpg', excerciseNum: 0 }],
	],
	47: [
		[{ imgUrl: 'img/FriendsPlus/Page47/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page47/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page47/3.jpg', excerciseNum: 0 }],
	],
	48: [
		[{ imgUrl: 'img/FriendsPlus/Page48/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page48/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page48/3.jpg', excerciseNum: 0 }],
	],
	49: [
		[{ imgUrl: 'img/FriendsPlus/Page49/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page49/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page49/3.jpg', excerciseNum: 0 }],
	],
	50: [
		[{ imgUrl: 'img/FriendsPlus/Page50/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page50/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page50/3.jpg', excerciseNum: 0 }],
	],
	51: [
		[{ imgUrl: 'img/FriendsPlus/Page51/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page51/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page51/3.jpg', excerciseNum: 0 }],
	],
	52: [
		[{ imgUrl: 'img/FriendsPlus/Page52/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page52/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page52/3.jpg', excerciseNum: 0 }],
	],
	53: [
		[{ imgUrl: 'img/FriendsPlus/Page53/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page53/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page53/3.jpg', excerciseNum: 0 }],
	],
	54: [
		[{ imgUrl: 'img/FriendsPlus/Page54/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page54/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page54/3.jpg', excerciseNum: 0 }],
	],
	55: [
		[{ imgUrl: 'img/FriendsPlus/Page55/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page55/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page55/3.jpg', excerciseNum: 0 }],
	],
	56: [
		[{ imgUrl: 'img/FriendsPlus/Page56/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page56/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page56/3.jpg', excerciseNum: 0 }],
	],
	57: [
		[{ imgUrl: 'img/FriendsPlus/Page57/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page57/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page57/3.jpg', excerciseNum: 0 }],
	],
	58: [
		[{ imgUrl: 'img/FriendsPlus/Page58/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page58/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page58/3.jpg', excerciseNum: 0 }],
	],
	59: [
		[{ imgUrl: 'img/FriendsPlus/Page59/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page59/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page59/3.jpg', excerciseNum: 0 }],
	],
	60: [
		[{ imgUrl: 'img/FriendsPlus/Page60/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page60/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page60/3.jpg', excerciseNum: 0 }],
	],
	61: [
		[{ imgUrl: 'img/FriendsPlus/Page61/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page61/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page61/3.jpg', excerciseNum: 0 }],
	],
	62: [
		[{ imgUrl: 'img/FriendsPlus/Page62/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page62/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page62/3.jpg', excerciseNum: 0 }],
	],
	63: [
		[{ imgUrl: 'img/FriendsPlus/Page63/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page63/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page63/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page63/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page63/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page63/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page63/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page63/10.jpg', excerciseNum: 0 }],
	],
	64: [
		[{ imgUrl: 'img/FriendsPlus/Page64/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page64/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page64/3.jpg', excerciseNum: 0 }],
	],
	65: [
		[{ imgUrl: 'img/FriendsPlus/Page65/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page65/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page65/3.jpg', excerciseNum: 0 }],
	],
	66: [
		[{ imgUrl: 'img/FriendsPlus/Page66/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page66/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page66/3.jpg', excerciseNum: 0 }],
	],
	67: [
		[{ imgUrl: 'img/FriendsPlus/Page67/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page67/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page67/3.jpg', excerciseNum: 0 }],
	],
	68: [
		[{ imgUrl: 'img/FriendsPlus/Page68/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page68/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page68/3.jpg', excerciseNum: 0 }],
	],
	69: [
		[{ imgUrl: 'img/FriendsPlus/Page69/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page69/3.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page69/4.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page69/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page69/7.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page69/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page69/9.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page69/10.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page69/11.jpg', excerciseNum: 0 }],
	],
	// 69: [
	// 	[{ imgUrl: 'img/FriendsPlus/Page69/1.jpg', excerciseNum: 0 }],
	// 	[{ imgUrl: 'img/FriendsPlus/Page69/2.jpg', excerciseNum: 1 }],
	// 	[{ imgUrl: 'img/FriendsPlus/Page69/3.jpg', excerciseNum: 0 }],
	// ],
	70: [
		[{ imgUrl: 'img/FriendsPlus/Page70/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page70/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page70/3.jpg', excerciseNum: 0 }],
	],
	71: [
		[{ imgUrl: 'img/FriendsPlus/Page71/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page71/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page71/3.jpg', excerciseNum: 0 }],
	],
	72: [
		[{ imgUrl: 'img/FriendsPlus/Page72/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page72/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page72/3.jpg', excerciseNum: 0 }],
	],
	73: [
		[{ imgUrl: 'img/FriendsPlus/Page73/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page73/2.jpg', excerciseNum: 1 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page73/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page73/5.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page73/6.jpg', excerciseNum: 0 }],
	],
	74: [
		[{ imgUrl: 'img/FriendsPlus/Page74/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/3.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page74/4.jpg', excerciseNum: 3 }],
		[{ imgUrl: 'img/FriendsPlus/Page74/5.jpg', excerciseNum: 0 }],
	],
	75: [
		[{ imgUrl: 'img/FriendsPlus/Page75/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page75/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page75/3.jpg', excerciseNum: 0 }],
	],
	76: [
		[{ imgUrl: 'img/FriendsPlus/Page76/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page76/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page76/3.jpg', excerciseNum: 0 }],
	],
	77: [
		[{ imgUrl: 'img/FriendsPlus/Page77/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page77/3.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/4.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/5.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page77/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page77/7.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/8.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/9.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page77/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page77/11.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page77/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page77/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page77/14.jpg', excerciseNum: 0 }],
	],
	78: [
		[{ imgUrl: 'img/FriendsPlus/Page78/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page78/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page78/3.jpg', excerciseNum: 0 }],
	],
	79: [
		[{ imgUrl: 'img/FriendsPlus/Page79/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page79/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page79/3.jpg', excerciseNum: 0 }],
	],
	80: [
		[{ imgUrl: 'img/FriendsPlus/Page80/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page80/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page80/3.jpg', excerciseNum: 0 }],
	],
	81: [
		[{ imgUrl: 'img/FriendsPlus/Page81/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page81/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page81/3.jpg', excerciseNum: 0 }],
	],
	82: [
		[{ imgUrl: 'img/FriendsPlus/Page82/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page82/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page82/3.jpg', excerciseNum: 0 }],
	],
	83: [
		[{ imgUrl: 'img/FriendsPlus/Page83/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page83/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page83/3.jpg', excerciseNum: 0 }],
	],
	84: [
		[{ imgUrl: 'img/FriendsPlus/Page84/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page84/2.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page84/3.jpg', excerciseNum: 0 }],
	],
	85: [
		[{ imgUrl: 'img/FriendsPlus/Page85/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page85/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page85/3.jpg', excerciseNum: 0 }],
	],
	86: [
		[{ imgUrl: 'img/FriendsPlus/Page86/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page86/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page86/3.jpg', excerciseNum: 0 }],
	],
	87: [
		[{ imgUrl: 'img/FriendsPlus/Page87/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page87/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page87/3.jpg', excerciseNum: 0 }],
	],
	88: [
		[{ imgUrl: 'img/FriendsPlus/Page88/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page88/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page88/3.jpg', excerciseNum: 0 }],
	],
	89: [
		[{ imgUrl: 'img/FriendsPlus/Page89/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page89/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page89/3.jpg', excerciseNum: 0 }],
	],
	90: [
		[{ imgUrl: 'img/FriendsPlus/Page90/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page90/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page90/3.jpg', excerciseNum: 0 }],
	],
	91: [
		[{ imgUrl: 'img/FriendsPlus/Page91/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page91/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page91/3.jpg', excerciseNum: 0 }],
	],
	92: [
		[{ imgUrl: 'img/FriendsPlus/Page92/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page92/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page92/3.jpg', excerciseNum: 0 }],
	],
	93: [
		[{ imgUrl: 'img/FriendsPlus/Page93/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page93/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page93/3.jpg', excerciseNum: 0 }],
	],
	94: [
		[{ imgUrl: 'img/FriendsPlus/Page94/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page94/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page94/3.jpg', excerciseNum: 0 }],
	],
	95: [
		[{ imgUrl: 'img/FriendsPlus/Page95/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page95/2.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page95/3.jpg', excerciseNum: 0 }],
	],
}
