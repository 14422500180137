import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'LQTAMN2-U5-P48-E1',
		audio: 'img/FriendsPlus/Page48/Audio/audio.e1.mp3',
		video: '',
		component: D1,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page48/E1/1.jpg' },
				{
					url: 'img/FriendsPlus/Page48/E1/2.jpg',
					audioUrl: 'img/FriendsPlus/Page48/Audio/tieude.e1.mp3',
				},
				{ url: 'img/FriendsPlus/Page48/E1/3.jpg' },
			],
			[
				// Column2
				{
					url: 'img/FriendsPlus/Page48/E1/4.jpg',
					audioUrl: 'img/FriendsPlus/Page48/Audio/anh1.e1.mp3',
				},
				{
					url: 'img/FriendsPlus/Page48/E1/5.jpg',
					audioUrl: 'img/FriendsPlus/Page48/Audio/anh2.e1.mp3',
				},
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 5',
		id: 'LQTAMN2-U5-P48-E2',
		audio: 'img/FriendsPlus/Page48/Audio/audio.e2.mp3',
		video: '',
		component: D1,
		recorder: true,
		isAllowSubmit: false,
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page48/E2/1.jpg' },
				{
					url: 'img/FriendsPlus/Page48/E2/2.jpg',
					audioUrl: 'img/FriendsPlus/Page48/Audio/tieude.e2.mp3',
				},
				{ url: 'img/FriendsPlus/Page48/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page48/E2/4.jpg' },
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 5',
		id: 'LQTAMN2-U5-P48-E3',
		audio: '',
		video: '',
		component: D1,
		recorder: true,
		isAllowSubmit: false,
		question: [],
		questionImage: [[{ url: 'img/FriendsPlus/Page48/E3/1.jpg' }]],
	},
}
export default json
